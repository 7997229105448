html {
  width: 100%;
  height: 100%;
  color: #d8d4cf;
  background-color: #131516;
}

.outer {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

textarea {
  height: 200px;
  max-width: 600px;
  width: 100%;
  resize: none;
  color: #d8d4cf;
  background-color: #131516;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 10px 12px;
  font-family: monospace;
  font-size: 15px;
}

button {
  color: #d8d4cf;
  background-color: #131516;
  border: 1px solid #fff;
  border-radius: 5px;
  margin: 5px;
  padding: 5px 10px;
}

#app {
  max-width: 600px;
  width: 100%;
  margin-right: 26px;
}

/*# sourceMappingURL=index.aee19796.css.map */
